<template>
  <CCard id="table-child-pool">
    <CDataTable
      :hover="hover"
      :striped="striped"
      :bordered="bordered"
      :small="small"
      :fixed="fixed"
      :items="items"
      :fields="fields"
      :items-per-page="10"
      :dark="dark"
      :pagination="true"
    >
      <td slot="#" slot-scope="{ item, index }">{{ index + 1 + offset}}</td>
      <template #action="{item}">
        <td class="py-2">
          <div class="group-btn">
<!--            <span @click="() => handleEdit(item.id)">-->
<!--              <CIcon class="icon-edit" :content="cilPencil" />-->
<!--            </span>-->
            <img src="@/assets/images/ico-edit-user.svg" alt="" @click="handleEdit(item.id)" class="hand edit-icon" v-if="hasUpdate === true"/>
            <span @click="() => handleConfig(item.id)">
              <CIcon class="icon-config" :content="cilBarChart" v-if="hasDetail === true"/>
            </span>
          </div>
        </td>
      </template>
    </CDataTable>
  </CCard>
</template>

<script>
import { cilBarChart, cilPencil } from '@coreui/icons';
import { mapState, mapActions } from "vuex";
export default {
  name: 'Table',
  data() {
    return {
      cilBarChart,
      cilPencil,
      hasUpdate: false,
      hasDetail: false
    };
  },
  props: {
    items: Array,
    fields: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    handleEdit: Function,
    handleConfig: Function,
    offset: Number
  },
  computed: {
     ...mapState({
       userPermission: state => state.auth.userPermissions,
     })
  },
  async mounted(){
    await this.checkPermission();
  },
  methods: {
    checkPermission(){
      if(this.userPermission.length > 0){
        this.userPermission.forEach(items =>{
          if(items.name === 'UPDATE_PARTNER'){
            this.hasUpdate = true
          }
          if(items.name === 'VIEW_LIST_CHILD_PARTNER'){
            this.hasDetail = true
          }
        })
      }
    }
  }
};
</script>

<style lang="scss">
#table-child-pool {
  table{
    td{
      color: #4D5666;
    }
  }
  nav {
    float: right;
  }
  .group-btn {
    display: flex;
    .icon-edit {
      color: #5706ff;
      cursor: pointer;
      margin-right: 10px;
    }
    .icon-config {
      color: #f20058;
      cursor: pointer;
    }
    img{
      width: 20px;
      height: 20px;
    }
    img:hover{
        cursor: pointer;
    }
    span{
      margin-left: 8px;
    }
  }
}
</style>
