<template>
  <div id="filter-child-pool" class="mt-3">
    <CRow>
      <CCol md="5" class="date-group">
        <h6 class="mt-1 mr-3">From date:</h6>
        <datepicker></datepicker>
      </CCol>
      <CCol md="5" class="date-group">
        <h6 class="mt-1 mr-3">To date:</h6>
        <datepicker></datepicker>
      </CCol>
      <CCol md="2">
        <CButton block color="info">Search</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
export default {
  components: {
    Datepicker
  }
};
</script>

<style lang="scss" scoped>
#filter-child-pool {
  .date-group {
    display: flex;
  }
}
</style>
