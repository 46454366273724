<template>
  <div class="childpool-wrap">
    <CCard>
      <CCardHeader class="justify-center">
        <CButton v-if="this.hasKey === true"
          color="success"
          shape="pill"
          class="mr-1 btn-custom-link"
          @click="goDirection('api-key')"
        >API Key Management</CButton>
        <CButton
          v-if="partner_type == 'AFFILIATE' && this.hasMemo === true"
          color="success"
          class="mr-1 btn-custom-link"
          shape="pill"
          @click="goDirection('grand-child')"
        >GrandChild Pool</CButton>
        <CButton
          v-if="partner_type == 'CHILD' && this.hasMemo === true "
          class="mr-1 btn-custom-link"
          color="success"
          shape="pill"
          @click="goDirection('transaction-memo')"
        >Transaction Memo</CButton>
        <CButton v-if="this.hasCommission === true"
          color="success"
          class="mr-1 btn-custom-link"
          shape="pill"
          @click="goDirection('commission')"
        >Commission</CButton>
      </CCardHeader>
      <CCardBody>
        <div class="mt-4">
          <Tables :items="items" :fields="fields" :offset="0" hover striped bordered />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Filters from '@/components/ChildPool/Filters';
import Charts from '@/components/ChildPool/Charts';
import Tables from '@/components/ChildPool/Tables';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  components: {
    // Filters,
    // Charts,
    Tables,
  },
  data() {
    return {
      partner_type: '',
      fields: ['#', 'platform', 'users', 'staking', 'commission'],
      items: [],
      hasKey: false,
      hasMemo: false,
      hasCommission: false
    };
  },
  computed: {
    partnerId() {
      return this.$route.params.id;
    },
    ...mapState({
      partner: state => state.partner.partnerSelected,
      userPermission: state => state.auth.userPermissions,
    }),
  },
  async mounted() {
    const childpool = await this.getChildpool();
    if (!childpool) {
      return;
    }

    this.partner_type = childpool.partner_type;
    await this.getChildpoolCoinsInfo();
    await this.checkPermission();
  },
  /**
   * Check up router before render element
   */
  async beforeRouteEnter(to, from, next) {
    try {
      const id = to.params.id;
      if (!id) {
        next();
      } else {
        next(vm => {
          if (vm.partner.id !== id) {
            vm.$router.push('/admin/childpool');
          } else {
            return;
          }
        });
      }
      return true;
    } catch (err) {
      this.$notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
      return false;
    }
  },
  methods: {
    goDirection(value) {
      const {
        current: { params },
      } = this.$router.history;
      this.$router.push(`${params.id}/${value}`);
    },
    changeType(value) {
      this.timeUnits = this[value.toLowerCase()];
      this.selected = value;
    },
    async getChildpool() {
      try {
        const result = await this.$http.get(endpoints.getGrandchild(this.partnerId));
        return result.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    /**
     * Get the list of childpool coins/fiats info anh assign to this.items to display in the talbe
     * @param
     * @return {boolean}
     */
    async getChildpoolCoinsInfo() {
      try {
        const params = {
          from: this.$moment(new Date(0)).format('MM/DD/YYYY'),
          to: this.$moment(new Date()).format('MM/DD/YYYY'),
        };

        // Async execute 2 $http function to booste the performance
        const [resCommission, resStaking] = await Promise.all([
          this.$http.get(endpoints.getCommissions(this.partnerId), { params: params }),
          // this.$http.get(endpoints.getStakingList(this.partnerId), { params: params }),
        ]);

        const itemsCommissionLst = resCommission.data.items;
        // const itemsStakingLst = resStaking.data.items;
        // Merge 2 items list, each includes some different object property
        // this.items = itemsStakingLst.map(item => {
        //   return {
        //     ...item,
        //     ...itemsCommissionLst.find(itemCommission => itemCommission.platform === item.platform),
        //   };
        // });

        // Rename this.items property due to backend api property is not the same name as frontend UI requirement
        this.items.forEach((item, idx) => {
          item.users = item.user;
          item.staking = item.balance;
          item.commission = item.amount;
          delete item.user;
          delete item.balance;
          delete item.amount;
        });
        return true;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
        return false;
      }
    },
    checkPermission(){
      if(this.userPermission.length > 0){
        this.userPermission.forEach(items =>{
          if(items.name === 'VIEW_LIST_KEY_PARTNER'){
            this.hasKey = true
          }
          if(items.name === 'VIEW_LIST_MEMO_PARTNER'){
            this.hasMemo = true
          }
          if(items.name === 'VIEW_LIST_COMMISSION_PARTNER'){
            this.hasCommission = true
          }
        })
      }
    }
  },
};
</script>
<style scoped lang="scss">
.btn-custom-link {
  min-width: 175px;
  padding: 0;
  width: 170px;
  height: 32px;
  border-radius: 32px;
  background: #3b4b7e;
  border-color: unset;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.btn-success:not(:disabled):not(.disabled):active {
  background: #3b4b7e;
}
.justify-center {
  display: flex;
  align-items: center;
}
</style>
